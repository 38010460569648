<template>
  <v-form lazy-validation ref="formRef">
    <v-row wrap>
      <v-col cols="12" v-if="classification === '2nd Year Student'">
        <v-select v-model="graduating" :items="gradOptions" :rules="required" label="Graduating this year?" outlined></v-select>
      </v-col>
      <v-col cols="12">
        <v-select v-model="weekOne" :items="weekOneOptions" :rules="required" label="Week One Course" outlined></v-select>
      </v-col>
      <v-col cols="12">
        <v-select v-model="weekTwo" :items="weekTwoOptions" :rules="required" label="Week Two Course" outlined></v-select>
      </v-col>
      <v-col cols="12">
        <v-select v-model="weekThree" :items="weekThreeOptions" :rules="required" label="Week Three Course" outlined></v-select>
      </v-col>
      <v-col cols="12" v-if="classification === '1st Year Student'">
        <v-select v-model="fallOnline" :items="fallOnlineOptions" :rules="required" label="Fall Online Course" outlined></v-select>
      </v-col>
      <v-col cols="12" v-if="classification === '2nd Year Student'">
        <v-select v-model="capstone" :items="capstoneOptions" :rules="required3rdYear" label="Capstone Course" outlined></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { onMounted, ref, watch } from '@vue/composition-api'
export default {
  props: {
    specialization: {
      type: String,
      required: true
    },
    classification: {
      type: String,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const graduating = ref('')
    const weekOne = ref('')
    const weekTwo = ref('')
    const weekThree = ref('')
    const fallOnline = ref('')
    const capstone = ref('')
    const gradOptions = ref([
      { text: 'Not graduating this year', value: 'No' },
      { text: 'Yes, graduating this year', value: 'Yes' }
    ])
    const weekOneOptions = ref([])
    const weekTwoOptions = ref([])
    const weekThreeOptions = ref([])
    const fallOnlineOptions = ref([])
    const capstoneOptions = ref([])

    const optionService = root.$feathers.service('forms/select-options')
    onMounted(() => {
      optionService.get('61ddd87890f59d31f887e195').then(({ options }) => { weekOneOptions.value = options })
      optionService.get('61ddd8f290f59d31f887e19b').then(({ options }) => { weekTwoOptions.value = options })
      optionService.get('61ddd97790f59d31f887e19e').then(({ options }) => { weekThreeOptions.value = options })
      optionService.get('61ddda0290f59d31f887e1b3').then(({ options }) => { fallOnlineOptions.value = options })
      optionService.get('61ddda2b90f59d31f887e1b6').then(({ options }) => { capstoneOptions.value = options })
    })

    function setDefaults () {
      if (props.specialization !== '' && props.classification !== '') {
        if (props.classification === '1st Year Student') {
          weekOne.value = 'EDU610'
          weekTwo.value = 'EDU630'
          weekThree.value = 'EDU712'
          fallOnline.value = 'EDU620'
          capstone.value = ''
        } else if (props.classification === '2nd Year Student') {
          weekOne.value = 'EDU750'
          weekTwo.value = props.specialization === 'EL' ? 'EDU725' : 'EDU735'
          weekThree.value = 'EDU740'
          fallOnline.value = ''
          capstone.value = props.specialization === 'EL' ? 'EDU785' : 'EDU790'
        }
      }
    }

    watch(() => props.specialization, () => {
      setDefaults()
    })
    watch(() => props.classification, () => {
      setDefaults()
    })

    const required = ref([
      v => !!v || 'Required'
    ])
    const required3rdYear = ref([
      v => !!v || props.classification !== '2nd Year Student' || 'Required'
    ])

    const formRef = ref(null)
    function validate () {
      return formRef.value.validate()
    }

    watch(graduating, (value) => {
      emit('update', { field: 'graduating', value })
    })
    watch(weekOne, (value) => {
      emit('update', { field: 'weekOne', value })
    })
    watch(weekTwo, (value) => {
      emit('update', { field: 'weekTwo', value })
    })
    watch(weekThree, (value) => {
      emit('update', { field: 'weekThree', value })
    })
    watch(fallOnline, (value) => {
      emit('update', { field: 'fallOnline', value })
    })
    watch(capstone, (value) => {
      emit('update', { field: 'capstone', value })
    })

    return {
      graduating,
      weekOne,
      weekTwo,
      weekThree,
      fallOnline,
      capstone,
      gradOptions,
      weekOneOptions,
      weekTwoOptions,
      weekThreeOptions,
      fallOnlineOptions,
      capstoneOptions,
      required,
      required3rdYear,
      formRef,
      validate
    }
  }
}
</script>
